body {
  margin: 0px;
}

.Resizer {
  z-index: 10000;
  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
  border: 10px solid #333642;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  cursor: row-resize;
  width: 100%;
  border: 8px solid #333642;
}

.Resizer.vertical {
  margin: 0 -5px;

  cursor: col-resize;
  border: 8px solid #333642;
}

.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.splitPane {
  position: inherit;
}

.editorTitle {
  padding: 10px;
  font-family: Tahoma;
  font-weight: bold;
}

.editorContainer {
  background-color: #1d1e22;
  height: 100%;
  color: #b7bbc8;
  padding-left: 5px;
  position: relative;
}

.previewIframe {
  width: 100vw;
  height: 100vh;
  border: 0;
  pointer-events: none;
}

.loading {
  width: 100vw;
  height: 100vh;
  background: #1d1e22;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Tahoma;
}

.header {
  height: 60px;
  background-color: #1d1e22;
  border-bottom: 5px solid #333642;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
}

.button {
  background-color: #333642;
  color: #fff;
  font-size: 15px;
  padding: 3px 20px;
  margin-right: 10px;
  border: 3px solid #444857;
  border-radius: 8px;
  cursor: pointer;
}

.button:hover,
.button:focus {
  background-color: #5a6074;
}
